import React from "react";
import ReactDynamicImport from 'react-dynamic-import';
import { detectMob } from "@src/Helper";

const loader = () => {
  if (!detectMob()) {
    return (
      import('@components/MyReferral/MyReferral')
    )
  } else {
    return (
      import('@components/MyReferral/MyReferralMobile')
    )
  }
}

const MyReferral = ReactDynamicImport({ loader })
// if (!detectMob()) {
//   import Landing from "../components/Landing/Landing";
// } else {
//   import LandingMobile from "../components/Landing/LandingMobile";
// }


const MyReferralPage = ({ location }) => <MyReferral location={location} />

export default MyReferralPage
